import { initForms } from './components/initForms';
import { initHashEmail } from './components/initHashEmail';
import { initScrollLocker } from './assets/initScrollLocker';
import { initLazyLoadFontsDescription } from './components/initLazyLoadFontsDescription';
import { initHashMessage } from './components/initHashMessage';
import { initAutoresizeTextarea } from './components/initAutoresizeTextarea';
import { initJivosite } from './components/initJivosite';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initLazyLoadFontsDescription();
    initScrollLocker();
    initAutoresizeTextarea();
    initForms();
    initHashEmail();
    initHashMessage();
    initJivosite();
}
